import React, { useState, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import requests from "../../services/requests";
import Card from "../../components/Cards";
import { CgArrowRightO, CgArrowLeftO } from "react-icons/cg";
import Container from "./styles";
import Modal from "../../components/Modals";
import Layout from "../../components/Layout";
import Table from "../../components/styles/Table";
import TitlePurple from "../../components/Typography/TitlePurple";
import SEO from "../../components/SEO";
import moment from "moment";
import { FiCheck, FiChevronRight, FiDownload } from "react-icons/fi";
import api from "../../services/api";
import Button from "../../components/Button";
import { ErrorMessage, Formik } from "formik";
import { toast } from "react-toastify";
import Select from "react-select";
import { FaBars, FaChevronDown, FaChevronUp, FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import Input from "../../components/Input/Input";
import { useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Paginate from "../../components/Paginate/Paginate";
import profile from "../../services/profile";
import XpandContainer from "../../components/XpandContainer";

const ProcessesMapPage = props => {
    const yup = require("yup");
    const [load, setLoad] = useState(false);
    const [list, setList] = useState([]);
    const [additionalField, setAdditionalField] = useState([]);
    const [modalExport, setModalExport] = useState(false);
    const [isDownloadingCsv, setIsDownloadingCsv] = useState(false);
    const [isDownloadingXlsx, setIsDownloadingXlsx] = useState(false);
    const [openFilterModal, setOpenFilterModal] = useState(false);
    const [openTab, setOpenTab] = useState("Filters Map");
    const [action, setAction] = useState(false);
    const { apiFilter, value: filterRedux } = useSelector(state => state.filter);
    const [selectionList, setSelectionList] = useState([]);
    const [usersFilter, setUsersFilter] = useState([]);
    const [userOptions, setUserOptions] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedList, setSelectedList] = useState({});
    const [selectedUser, setSelectedUser] = useState({});
    const [oldUser, setOldUser] = useState({});
    const [oldName, setOldName] = useState("");
    const [filterapplied, setFilterApplied] = useState({});
    const [filters, setFilters] = useState({});
    const [filterEditing, setFilterEditing] = useState("");
    const [isEdit, setIsEdit] = useState(false);
    const [titleSelectionApplied, setTitleSelectionApplied] = useState("");
    const [titleSelection, setTitleSelection] = useState("");
    const [modalContentTitle, setModalContentTitle] = useState("");
    const [modalContent, setModalContent] = useState("");
    const [visibleModalContent, setVisibleModalContent] = useState("");

    const [userSelectionApplied, setUserSelectionApplied] = useState("");
    const [userSelection, setUserSelection] = useState("");
    const [sendEmail, setSendEmail] = useState(false);
    const [meta, setMeta] = useState({
        current_page: 1,
    });
    const [metaSelectionMap, setMetaSelectionMap] = useState({
        current_page: 1,
    });
    const [actionPag, setActionPag] = useState(1);
    const [actionPagSelectionMap, setActionPagSelectionMap] = useState(1);

    const initialFields = [
        {
            id: "pre-embarque",
            name: "Pré Embarque",
            active: true,
            opened: true,
            subFields: [
                {
                    id: "ref",
                    name: "Ref",
                    active: true,
                    width: 150,
                },
                {
                    id: "cliente",
                    name: "Cliente",
                    active: true,
                    width: 300,
                },
                {
                    id: "etapa",
                    name: "Etapa",
                    active: true,
                    width: 150,
                },
                {
                    id: "processo",
                    name: "Processo",
                    active: true,
                    width: 150,
                },
                {
                    id: "tipoOperacao",
                    name: "Tipo Operação",
                    active: true,
                    width: 250,
                },
                {
                    id: "frete",
                    name: "Modal",
                    active: true,
                    width: 100,
                },
                // {
                //     id: "valorFrete",
                //     name: "Valor Frete",
                //     active: true,
                //     width: 150,
                // },
                {
                    id: "valorFreteUsd",
                    name: "Valor Frete USD",
                    active: true,
                    width: 150,
                }, {
                    id: 'refExportador',
                    name: 'Ref. Exportador',
                    active: true,
                    width: 150,
                }, {
                    id: "filial",
                    name: "Filial",
                    active: true,
                    width: 75,
                },
                {
                    id: "incoterm",
                    name: "Incoterm",
                    active: true,
                    width: 75,
                },
                {
                    id: "exportador",
                    name: "Exportador",
                    active: true,
                    width: 300,
                },
            ],
        },
        {
            id: "transito",
            name: "Trânsito",
            active: true,
            opened: false,
            subFields: [
                {
                    id: "portoOrigem",
                    name: "Porto Origem",
                    active: true,
                    width: 200,
                },
                {
                    id: "portoDestino",
                    name: "Porto Destino",
                    active: true,
                    width: 200,
                },
                {
                    id: "weight",
                    name: "Peso (kg)",
                    active: true,
                    width: 150,
                },
                /*{
                    id: 'codAcondicEmbalagem',
                    name: 'Cód. Acondic./Embalagem',
                    active: true,
                    width: 200,
                }, */ {
                    id: "descricaoProduto",
                    name: "Descrição do Produto",
                    active: true,
                    width: 300,
                },
                {
                    id: "produtoRefMaterial",
                    name: "Produto / Ref. Material",
                    active: true,
                    width: 200,
                },
                {
                    id: "invoice",
                    name: "Invoice",
                    active: true,
                    width: 200,
                },
                {
                    id: "qtde",
                    name: "Qtde",
                    active: true,
                    width: 100,
                },
                {
                    id: "valorUnit",
                    name: "Valor Unit.",
                    active: true,
                    width: 150,
                },
                {
                    id: "moeda",
                    name: "Moeda",
                    active: true,
                    width: 150,
                },
                {
                    id: "valorTotalUSD",
                    name: "Valor Total",
                    active: true,
                    width: 150,
                },
                {
                    id: "li",
                    name: "LI",
                    active: true,
                    width: 150,
                },
                {
                    id: "ncm",
                    name: "NCM",
                    active: true,
                    width: 150,
                },
                {
                    id: "imo",
                    name: "IMO",
                    active: true,
                    width: 150,
                },
                {
                    id: "vencimentoCambio",
                    name: "Vencimento do Câmbio",
                    active: true,
                    width: 150,
                },
                {
                    id: "originais",
                    name: "Originais",
                    active: true,
                    width: 150,
                },
                {
                    id: "blAwbCrt",
                    name: "BL/AWB/CRT",
                    active: true,
                    width: 200,
                },
                {
                    id: "crt20",
                    name: "CRT 20",
                    active: true,
                    width: 100,
                },
                {
                    id: "crt40",
                    name: "CRT 40",
                    active: true,
                    width: 100,
                },
                {
                    id: "tipocntr",
                    name: "Tipo CNTR",
                    active: true,
                    width: 250,
                },
                {
                    id: "navio",
                    name: "Navio",
                    active: true,
                    width: 250,
                },
                {
                    id: "etd",
                    name: "ETD",
                    active: true,
                    width: 150,
                },
                {
                    id: "envioCartaProtesto",
                    name: "Envio Carta Protesto",
                    active: true,
                    width: 150,
                },
                {
                    id: "mapaStatus",
                    name: "Mapa Status",
                    active: true,
                    width: 150,
                },
                {
                    id: "eta",
                    name: "ETA",
                    active: true,
                    width: 150,
                },
                {
                    id: "presencaCarga",
                    name: "Presença Carga",
                    active: true,
                    width: 150,
                },
                {
                    id: "freeTime",
                    name: "Free Time",
                    active: true,
                    width: 150,
                },
                {
                    id: "dataVencFreeTime",
                    name: "Data de Venc. Free Time",
                    active: true,
                    width: 150,
                },
            ],
        },
        {
            id: "desembaraco",
            name: "Desembaraço",
            active: true,
            opened: false,
            subFields: [
                /*{
                    id: 'dataRemocao',
                    name: 'Data de Remoção',
                    active: true,
                    width: 150,
                },{
                    id: 'presencaCarga',
                    name: 'Presença de Carga',
                    active: true,
                    width: 150,
                },*/ {
                    id: "contratoCambio",
                    name: "Contrato de Câmbio",
                    active: true,
                    width: 150,
                },
                {
                    id: "modalidadePagamento",
                    name: "Modalidade de Pagamento",
                    active: true,
                    width: 150,
                },
                // {
                //     id: "previsaoEntrega",
                //     name: "Previsão de Entrega",
                //     active: true,
                //     width: 150,
                // },
                /*{
                    id: 'pgtoVendemmia',
                    name: 'Pgto. Vendemmia',
                    active: true,
                    width: 200,
                },*/ {
                    id: "di",
                    name: "DI",
                    active: true,
                    width: 150,
                },
                {
                    id: "dataRegistro",
                    name: "Data do Registro",
                    active: true,
                    width: 150,
                },
                {
                    id: "canal",
                    name: "Canal",
                    active: true,
                    width: 100,
                },
                {
                    id: "dataDesembaraco",
                    name: "Data Desembaraço",
                    active: true,
                    width: 150,
                },
                {
                    id: "localDesembaraco",
                    name: "Local Desembaraço",
                    active: true,
                    width: 250,
                },
            ],
        },
        {
            id: "faturamento",
            name: "Faturamento",
            active: true,
            opened: false,
            subFields: [
                /*{
                    id: 'aPagarVendemmia',
                    name: 'A pagar Vendemmia',
                    active: true,
                    width: 150,
                }, {
                    id: 'dataPgto',
                    name: 'Data Pgto.',
                    active: true,
                    width: 150,
                }, {
                    id: 'previsaoPagamento',
                    name: 'Prev. Pgto.',
                    active: true,
                    width: 150,
                },*/
                // {
                //     id: "dataVencArmaz",
                //     name: "Data de Venc. Armaz.",
                //     active: true,
                //     width: 150,
                // },
                {
                    id: "acompDetalhado",
                    name: "Acomp. Detalhado",
                    active: true,
                    width: 200,
                },
                // {
                //     id: "nDanfeCompVdm",
                //     name: "Nº DANFE Comp. VDM",
                //     active: true,
                //     width: 200,
                // },
                /* {
                    id: 'lote',
                    name: 'Lote',
                    active: true,
                    width: 150,
                }, {
                    id: 'nContainers',
                    name: 'Nº dos Containers',
                    active: true,
                    width: 100,
                },*/
                // {
                //     id: "coleta",
                //     name: "Data Carregamento",
                //     active: true,
                //     width: 150,
                // },
                {
                    id: "valorFatNf",
                    name: "Valor Fat. (NF)",
                    active: true,
                    width: 150,
                },
                {
                    id: "dataFaturamento",
                    name: "Data de Faturamento",
                    active: true,
                    width: 150,
                },
            ],
        },
        {
            id: "entrega",
            name: "Entrega",
            active: true,
            opened: false,
            subFields: [
                // {
                //     id: "prevEntrega",
                //     name: "Previsão de Entrega",
                //     active: true,
                //     width: 200,
                // },
                {
                    id: "dataEntrega",
                    name: "Data de Entrega",
                    active: true,
                    width: 200,
                }
            ],
        },
    ];

    function handleScroll(direction) {
        const element = document.getElementById("table-responsive");
        const { scrollLeft } = element;
        document
            .getElementById("table-responsive")
            .scrollTo({ left: direction === "left" ? scrollLeft - 300 : scrollLeft + 300, behavior: "smooth" });
    }

    const [filterFields, setFilterFields] = useState(initialFields);
    const [apliedFields, setApliedFields] = useState([]);

    const actionModalExport = () => {
        setModalExport(!modalExport);
    };

    //Função para retornar comentários do processo
    const returnComments = entity => {
        let comments = "";
        const total = entity?.length - 1;

        entity.map((element, key) => {
            comments += element?.description;

            if (key < total) comments += " <br /> ";
        });

        return comments;
    };

    const actionModalContent = () => {
        setVisibleModalContent(!visibleModalContent);
    };

    const listMap = () => {
        setLoad(true);

        requests.listMap(filterapplied, meta.current_page > 0 ? meta.current_page : 1).then(data => {
            setList(data.data);
            setMeta(data.meta);
            setLoad(false);
        });
    };

    const listAdditionalFields = () => {
        setLoad(true);

        requests.listAditionalFields().then(data => {
            var aux = initialFields;
            // var aux2 = [];
            aux.forEach((item, index) => {
                if (item.id === "camposAdicionais") {
                    data.data.map((adittionalField, key) => {
                        aux[index].subFields.push({
                            id: "camposAdicionais",
                            name: adittionalField.label,
                            active: true,
                        });
                    });
                }
            });
            setLoad(false);
            refreshApliedFields(aux);
            setFilterFields(aux);
        });

        if (additionalField.length) {
            initialFields.push({
                id: "camposAdicionais",
                name: "Campos adicionais",
                active: true,
                opened: false,
                subFields: [],
            })
        };
    };

    const downloadCsv = () => {
        setIsDownloadingCsv(true);
        var filters = filterapplied;
        filters.fields = apliedFields;

        api.get(`/map-filter/map/export-csv`, {
            responseType: "blob",
            headers: {
                Authorization: "Bearer " + window.localStorage.getItem("session-token"),
            },
            params: { filters: JSON.stringify(filters) },
        })
            .then(response => {
                const fileType = response.headers["content-type"];
                const blob = new Blob([response.data], { fileType });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "export.csv");
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .finally(() => {
                setIsDownloadingCsv(false);
            });
    };

    const downloadXlsx = () => {
        setIsDownloadingXlsx(true);
        var filters = filterapplied;
        filters.fields = apliedFields;

        api.get(`/map-filter/map/export-xlsx`, {
            responseType: "blob",
            headers: {
                Authorization: "Bearer " + window.localStorage.getItem("session-token"),
            },
            params: { filters: JSON.stringify(filters) },
        })
            .then(response => {
                const fileType = response.headers["content-type"];
                const blob = new Blob([response.data], { fileType });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "export.xlsx");
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .finally(() => {
                setIsDownloadingXlsx(false);
            });
    };

    useEffect(() => {
        listMap();
    }, [filterapplied, actionPag]);

    const loadSelectionList = () => {
        requests.listSelection(metaSelectionMap.current_page > 0 ? metaSelectionMap.current_page : 1).then(list => {
            setMetaSelectionMap(list.meta);
            setSelectionList(list.data);
        });
    };

    function formatDocument(document) {
        if (document.length === 14) return document.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
        else return document;
    }

    const refreshList = () => {
        const auxData = {};

        Object.entries(apiFilter).forEach(([key, filter]) => {
            if (filter.length > 10) {
                auxData[key] = filter?.map(entity => ({
                    ...entity,
                    value: entity.identifier,
                    label: `${entity.name} - ${formatDocument(entity.documentNumber)} (${entity?.conexosId})`,
                    checked: !!selectedList[key]?.find(
                        arr =>
                            (selectedList["searchClients"] === "conexos" && key === "clients"
                                ? entity?.conexosId
                                : entity.identifier) === arr
                    ),
                }));
            } else {
                auxData[key] = filter?.map(entity => {
                    if (typeof entity === "object") {
                        return {
                            ...entity,
                            checked: !!selectedList[key]?.find(arr => entity.identifier === arr),
                        };
                    }
                    return {
                        identifier: entity,
                        description: entity,
                        checked: !!selectedList[key]?.find(arr => entity === arr),
                    };
                });
            }
        });

        // setSelectedList(Object.assign({}, selectedList));
        setFilters(Object.assign({}, auxData));
    };

    useEffect(() => {
        loadSelectionList();
        refreshList();
    }, [openTab, apiFilter, selectedList, actionPagSelectionMap]);

    function clone(obj) {
        var copy;

        // Handle the 3 simple types, and null or undefined
        if (null === obj || "object" !== typeof obj) return obj;

        // Handle Date
        if (obj instanceof Date) {
            copy = new Date();
            copy.setTime(obj.getTime());
            return copy;
        }

        // Handle Array
        if (obj instanceof Array) {
            copy = [];
            for (var i = 0, len = obj.length; i < len; i++) {
                copy[i] = clone(obj[i]);
            }
            return copy;
        }

        // Handle Object
        if (obj instanceof Object) {
            copy = {};
            for (var attr in obj) {
                if (obj.hasOwnProperty(attr)) copy[attr] = clone(obj[attr]);
            }
            return copy;
        }

        throw new Error("Unable to copy obj! Its type isn't supported.");
    }

    const refreshApliedFields = fields => {
        var copy = [];
        for (var attr in fields) {
            if (fields.hasOwnProperty(attr)) copy[attr] = clone(fields[attr]);
        }
        setApliedFields(copy);
    };

    useEffect(() => {
        getUsers();
        listAdditionalFields();
    }, []);

    function handleFilter(entity, key) {
        if (entity instanceof Array) {
            if (!selectedList[key]) {
                selectedList[key] = [];
            }

            if (key === "clients" && selectedList["searchClients"] === "conexos") {
                selectedList[key] = entity.map(e => e.conexosId);
                selectedList["searchClients"] = "conexos";
            } else {
                selectedList[key] = entity.map(e => e.identifier);
                selectedList["searchClients"] = "cnpj";
            }

            if (!selectedList[key].length) {
                delete selectedList[key];
            }
            setSelectedList(selectedList);
        } else {
            if (key === "searchClients") {
                selectedList["searchClients"] = entity;
            } else {
                entity.checked = !entity.checked;
                let value = selectedList[key];

                if (entity.checked) {
                    if (!value) {
                        value = [];
                    }
                    value = [...value, entity.identifier];
                } else {
                    value = value.filter(f => f !== entity.identifier);
                }

                let auxFilter = {};
                if (!value.length) {
                    delete selectedList[key];
                    auxFilter = { ...selectedList };
                } else {
                    auxFilter = { ...selectedList, [key]: value };
                }
                setSelectedList(auxFilter);
            }
        }
        refreshList();
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = result => {
        if (!result.destination) {
            return;
        }

        const fieldsAux = reorder(filterFields, result.source.index, result.destination.index);

        setFilterFields(fieldsAux);
        setAction(!action);
    };

    const onDragEndChildrens = (result, fatherIndex) => {
        if (!result.destination) {
            return;
        }

        const fieldsAux = reorder(filterFields[fatherIndex].subFields, result.source.index, result.destination.index);

        var aux = filterFields;
        aux[fatherIndex].subFields = fieldsAux;
        setFilterFields(aux);
        setAction(!action);
    };

    const renderPrimaryTitles = () => {
        var i = 0;
        return apliedFields.map(field => {
            if (!field.active) {
                return "";
            }

            i++;

            var qttSubItens = 0;
            field.subFields.forEach(field => {
                if (field.active) {
                    qttSubItens++;
                }
            });

            return (
                <th
                    colspan={qttSubItens}
                    className="text-roxo_oficial border-b border-roxo_oficial pl-3"
                    Style={`${i !== 1 && "border-left: 1px solid #422C76;"}`}>
                    {field.name}
                </th>
            );
        });
    };

    const renderSubTitles = () => {
        var i = 0;
        return apliedFields.map(field => {
            if (!field.active) {
                return "";
            }

            i++;

            var j = 0;
            return field.subFields.map(field => {
                if (!field.active) {
                    return "";
                }

                j++;

                return (
                    <th
                        className="text-roxo_oficial pl-3"
                        Style={`${i !== 1 && j === 1 && "border-left: 1px solid #422C76;"}`}>
                        {field.name}
                    </th>
                );
            });
        });
    };

    const getContent = (map, id) => {
        var content = "-";

        switch (id) {
            case "ref":
                content = map?.process?.clientInternalCode ? map?.process?.clientInternalCode : "-";
                break;
            case "cliente":
                content = map?.process?.clientOrderer ? map?.process?.clientOrderer : "-";
                break;
            case "etapa":
                content = map?.process?.currentStageModality ? map?.process?.currentStageModality : "-";
                break;
            case "processo":
                content = map?.process?.code ? map?.process?.code : "-";
                break;
            case "tipoOperacao":
                content = map?.tipoOperacao ? map?.tipoOperacao : "-";
                break;
            case "frete":
                content = map?.freight ? map?.freight : "-";
                break;
            case "valorFrete":
                content = map?.freightInternational ? map?.freightInternational : "-";
                break;
            case "valorFreteUsd":
                content = map?.freightUsd ? map?.freightUsd : "-";
                break;
            case "refExportador":
                content = map?.refExportador ? map?.refExportador : "-";
                break;
            case "filial":
                content = map?.filial ? map?.filial : "-";
                break;
            case "incoterm":
                content = map?.incoterm ? map?.incoterm : "-";
                break;
            case "exportador":
                content = map?.exporter ? map?.exporter : "-";
                break;
            case "portoOrigem":
                content = map?.portoOrigem ? map?.portoOrigem : "-";
                break;
            case "portoDestino":
                content = map?.portoDestino ? map?.portoDestino : "-";
                break;
            case "weight":
                content = map?.weight ? map?.weight : "-";
                break;
            case "produtoRefMaterial":
                content = map?.refMaterial ? map?.refMaterial : "-";
                break;
            case "descricaoProduto":
                content = map?.productDescription ? map?.productDescription : "-";
                break;
            case "codAcondicEmbalagem":
                content = map?.package ? map?.package : "-";
                break;
            case "codProduto":
                content = map?.productCode ? map?.productCode : "-";
                break;
            case "invoice":
                content = map?.invoice ? map?.invoice : "-";
                break;
            case "cnt":
                content = map?.cnt ? map?.cnt : "-";
                break;
            case "qtde":
                content = map?.qtt ? map?.qtt : "-";
                break;
            case "valorUnit":
                content = map?.unitValue ? map?.unitValue : "-";
                break;
            case "valorTotalUSD":
                content = map?.usdValue ? map?.usdValue : "-";
                break;
            case "moeda":
                content = map?.currencyType ? map?.currencyType : "-";
                break;
            case "valorTotalFaturamento":
                content = map?.totalValue ? map?.totalValue : "-";
                break;
            case "li":
                content = map?.li ? map?.li : "-";
                break;
            case "ncm":
                content = map?.ncm ? map?.ncm : "-";
                break;
            case "imo":
                content = map?.cargaImo ? map?.cargaImo : "-";
                break;
            case "vencimentoCambio":
                content = map?.exchangeMaturity ? map?.exchangeMaturity : "-";
                break;
            case "originais":
                content = map?.originais ? moment(map?.originais).format("DD/MM/Y") : "-";
                break;
            case "prevEntrega":
                content = map?.deliveryPrevision ? moment(map?.deliveryPrevision).format("DD/MM/Y") : "-";
                break;
            case "dataEntrega":
                content = map?.deliveredAt ? moment(map?.deliveredAt).format("DD/MM/Y") : "-";
                break;
            case "blAwbCrt":
                content = map?.blAwbCrt ? map?.blAwbCrt : "-";
                break;
            case "crt20":
                content = map?.ctnr20 ? map?.ctnr20 : "-";
                break;
            case "crt40":
                content = map?.ctnr40 ? map?.ctnr40 : "-";
                break;
            case "tipocntr":
                content = map?.tipoCNTR ? map?.tipoCNTR : "-";
                break;
            case "navio":
                content = map?.vessel ? map?.vessel : "-";
                break;
            case "etd":
                content = map?.etd ? moment(map?.etd).format("DD/MM/Y") : "-";
                break;
            case "envioCartaProtesto":
                content = map?.protesto ? moment(map?.protesto).format("DD/MM/Y") : "-";
                break;
            case "mapaStatus":
                content = map?.statusLiberacao ? map?.statusLiberacao : "-";
                break;
            case "eta":
                content = map?.eta ? moment(map?.eta).format("DD/MM/Y") : "-";
                break;
            case "presencaCarga":
                content = map?.presencaCarga ? moment(map?.presencaCarga).format("DD/MM/Y") : "-";
                break;
            case "dataRemocao":
                content = map?.removalDate ? moment(map?.removalDate).format("DD/MM/Y") : "-";
                break;
            case "previsaoEntrega":
                content = map?.deliveryPrevision ? moment(map?.deliveryPrevision).format("DD/MM/Y") : "-";
                break;
            case "di":
                content = map?.diFormatted ? map?.diFormatted : "-";
                break;
            case "dataRegistro":
                content = map?.process?.clearenceDiAtFormatted ? map?.process?.clearenceDiAtFormatted : "-";
                break;
            case "canal":
                content = map?.process?.channel ? map?.process?.channel : "-";
                break;
            case "dataDesembaraco":
                content = map?.process?.clearenceCiAtFormatted ? map?.process?.clearenceCiAtFormatted : "-";
                break;
            case "localDesembaraco":
                content = map?.clearancePlace ? map?.clearancePlace : "-";
                break;
            case "freeTime":
                content = map?.freeTime ? map?.freeTime : "-";
                break;
            case "dataVencFreeTime":
                content = map?.dtVencFreeTime ? moment(map?.dtVencFreeTime).format("DD/MM/Y") : "-";
                break;
            case "contratoCambio":
                content = map?.cambio ? map?.cambio : "-";
                break;
            case "modalidadePagamento":
                content = map?.modalidade ? map?.modalidade : "-";
                break;
            case "aPagarVendemmia":
                content = map?.toPayVendemmia ? map?.toPayVendemmia : "-";
                break;
            case "dataPgto":
                content = map?.paymentDate ? moment(map?.paymentDate).format("DD/MM/Y") : "-";
                break;
            case "previsaoPagamento":
                content = map?.billingPrevision ? moment(map?.billingPrevision).format("DD/MM/Y") : "-";
                break;
            case "acompDetalhado":
                //content = map?.detailedTracking ? map?.detailedTracking : "-";
                content =
                    map?.process?.comments && map?.process?.comments?.length > 0
                        ? returnComments(map?.process?.comments)
                        : "-";
                break;
            case "coleta":
                content = map?.coleta ? moment(map?.coleta).format("DD/MM/Y") : "-";
                break;
            case "valorFatNf":
                content = map?.revenueValue ? map?.revenueValue : "-";
                break;
            case "dataFaturamento":
                content = map?.revenueDate ? moment(map?.revenueDate).format("DD/MM/Y") : "-";
                break;
            case "camposAdicionais":
                content = map?.additionalField ? map?.additionalField : "-";
                break;
        }

        return content;
    };

    const renderContents = map => {
        var i = 0;
        return apliedFields.map(field => {
            if (!field.active) {
                return "";
            }

            i++;

            var j = 0;
            return field.subFields.map(field => {
                if (!field.active) {
                    return "";
                }

                j++;

                return (
                    <td
                        className="pl-3"
                        Style={`min-width: ${field.width}px; padding-right: 15px; ${i !== 1 && j === 1 && "border-left: 1px solid #422C76;"
                            }`}>
                        {getContent(map, field.id).length > 30 ? (
                            <span>
                                {getContent(map, field.id).substring(0, 30)}
                                <span
                                    className="cursor-pointer text-roxo_oficial font-bold ml-2"
                                    onClick={() => {
                                        setModalContentTitle(field.name);
                                        setModalContent(getContent(map, field.id));
                                        setVisibleModalContent(true);
                                    }}>
                                    [...]
                                </span>
                            </span>
                        ) : (
                            getContent(map, field.id)
                        )}
                    </td>
                );
            });
        });
    };

    const refreshOptions = selected => {
        if (selected !== null) {
            var aux = [];
            selected?.length &&
                selected.map(data => {
                    aux.push(data.identifier);
                });

            api.get(`/map-filter/client/users`, {
                headers: {
                    Authorization: "Bearer " + window.localStorage.getItem("session-token"),
                },
                params: { clients: aux },
            }).then(res => {
                setUsers(res?.data);
                var options = res?.data.map(data => {
                    return {
                        value: data.identifier,
                        label: data.name,
                    };
                });

                options.unshift({
                    label: "Todos usuários da empresa",
                    value: "all-users",
                });
                setUserOptions(options);
            });
        } else {
            api.get(`/map-filter/client/users`, {
                headers: {
                    Authorization: "Bearer " + window.localStorage.getItem("session-token"),
                },
            }).then(res => {
                setUsers(res?.data);
                setUserOptions(
                    res?.data.map(data => {
                        return {
                            value: data.identifier,
                            label: data.name,
                        };
                    })
                );
            });
        }
    };

    const getUsers = () => {
        api.get(`/map-filter/client/users`, {
            headers: {
                Authorization: "Bearer " + window.localStorage.getItem("session-token"),
            },
        }).then(res => {
            setUsers(res?.data);
            setUserOptions(
                res?.data.map(data => {
                    return {
                        value: data.identifier,
                        label: data.name,
                    };
                })
            );
        });
    };

    return (
        <Layout>
            <SEO title="Mapa" />
            {/* <Submenu type={{ map: true, export: true }} actionModalExportar={actionModalExport} /> */}

            <div className="bg-white flex float-right mr-4 mt-4 flex-wrap">
                <div className="flex flex-wrap items-center lg:m-0">
                    <Button
                        className="mr-4 font-medium h-10"
                        onClick={() => {
                            setOpenFilterModal(true);
                            if (typeof selectedUser.value === "undefined") {
                                setSelectedUser({
                                    label: window.localStorage.getItem("profile-name"),
                                    value: window.localStorage.getItem("profile-identifier"),
                                });
                            }
                        }}>
                        filtros <FiChevronRight size="1.1rem" />
                        {!!Object.values(selectedList).length && (
                            <div className="self-start rounded-full w-2 h-2 bg-rosa_oficial" />
                        )}
                    </Button>
                </div>

                <div className="flex flex-wrap items-center lg:m-0">
                    <Button className="mr-4 font-medium h-10 bg-roxo_oficial" onClick={() => setModalExport(true)}>
                        <FiDownload size="1.1rem" className="text-white" /> <div className="text-white ml-2">exportar</div>
                    </Button>
                </div>
            </div>

            {/* Modal de filtros */}
            <Modal
                show={openFilterModal}
                borderTitle={false}
                onClose={() => {
                    setOpenFilterModal(false);
                }}
                size="xs:w-7/12 lg:w-7/12 min-h-80%"
                title={
                    <div className="flex items-center">
                        <h5 className={`mr-6 text-roxo_oficial font-bold`}>SELEÇÃO DE FILTROS</h5>
                    </div>
                }>
                <div className="flex items-center border-b mb-2">
                    <h5
                        role="button"
                        onClick={() => setOpenTab("Filters Map")}
                        className={` mr-4 cursor-pointer ${openTab === "Filters Map" ? "text-roxo_oficial" : "text-C6"}`}>
                        Seleção aplicada
                    </h5>
                    <h5
                        role="button"
                        onClick={() => setOpenTab("Selection")}
                        className={` mr-4 cursor-pointer ${openTab === "Selection" ? "text-roxo_oficial" : "text-C6"}`}>
                        Seleções salvas
                    </h5>
                </div>
                {openTab === "Filters Map" ? (
                    <div className="flex flex-col w-full mt-6">
                        <Formik
                            // enableReinitialize={true}
                            validationSchema={yup.object().shape({
                                name: yup.string().typeError("Campo obrigatório").required("Campo obrigatório"),
                            })}
                            initialValues={{
                                name: titleSelection,
                                user: selectedUser,
                                edit: "",
                                sendEmail: sendEmail,
                            }}
                            onSubmit={(values, { setFieldError, setSubmitting }) => {
                                let aux = {
                                    ...values,
                                    sendEmail: sendEmail ? 1 : 0,
                                    parent: filterEditing,
                                };
                                var usersToSend = [];
                                if (selectedUser?.value === "all-users") {
                                    users.forEach(user => {
                                        usersToSend.push(user.identifier);
                                    });

                                    aux = {
                                        ...aux,
                                        user: usersToSend,
                                    };
                                } else {
                                    aux = {
                                        ...aux,
                                        user: selectedUser ? [selectedUser.value] : [],
                                    };
                                }

                                if (profile.role === "ROLE_CLIENT") {
                                    aux.user = [profile.identifier];
                                }

                                setSubmitting(true);
                                setTitleSelection(values.name);
                                setUserSelection(selectedUser);

                                if (isEdit) {
                                    requests
                                        .newSelectionMap(aux, selectedList, filterFields)
                                        .then(data => {
                                            toast.success("Seleção alterada com sucesso!");
                                            setTitleSelectionApplied(values.name);
                                            setUserSelection({ label: data?.user.name, value: data?.user.identifier });
                                            setSelectedUser({ label: data?.user.name, value: data?.user.identifier });
                                            setFilterApplied(selectedList);
                                            setSendEmail(data.sendEmail);
                                            refreshApliedFields(filterFields);
                                            setOpenFilterModal(false);
                                            setFilterEditing(data.identifier);
                                            setSubmitting(false);
                                            requests.deleteSelection(filterEditing);
                                        })
                                        .catch(error => {
                                            setSubmitting(false);
                                        });

                                    if (
                                        oldUser.value &&
                                        typeof selectedUser.value !== "undefined" &&
                                        oldUser.value !== selectedUser.value
                                    ) {
                                        let aux = {
                                            ...values,
                                            user: [oldUser.value],
                                            name: oldName,
                                            sendEmail: 0,
                                        };
                                        requests
                                            .newSelectionMap(aux, selectedList, filterFields)
                                            .then(data => {
                                                setSubmitting(false);
                                            })
                                            .catch(error => {
                                                setSubmitting(false);
                                            });
                                    }
                                } else {
                                    requests
                                        .newSelectionMap(aux, selectedList, filterFields)
                                        .then(data => {
                                            toast.success("Nova seleção salva com sucesso!");
                                            setFilterEditing(data.identifier);
                                            setSubmitting(false);
                                            setTitleSelectionApplied(values.name);
                                            setUserSelectionApplied({
                                                label: data?.user.name,
                                                value: data?.user.identifier,
                                            });
                                            setFilterApplied(selectedList);
                                            refreshApliedFields(filterFields);
                                            setOpenFilterModal(false);
                                        })
                                        .catch(error => {
                                            setSubmitting(false);
                                        });
                                }
                            }}>
                            {({
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                values,
                                resetForm,
                                setSubmitting,
                                setFieldValue,
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="flex">
                                        <div className="w-4/12">
                                            <div className="w-full">
                                                <label htmlFor="name" className="text-roxo_oficial font-bold">
                                                    Nome da seleção
                                                </label>
                                                <Input
                                                    name="name"
                                                    id="name"
                                                    onChange={handleChange}
                                                    value={values.name}
                                                    required
                                                />
                                                <ErrorMessage
                                                    component="label"
                                                    name="name"
                                                    className="text-red font-light w-full"
                                                />
                                            </div>
                                            {profile.role !== "ROLE_CLIENT" && (
                                                <>
                                                    <div className="w-full">
                                                        <h6 className="mt-4">Empresas</h6>

                                                        {Object.entries(filters).map(
                                                            ([key, filter]) =>
                                                                filter.length > 10 && (
                                                                    <Select
                                                                        isMulti
                                                                        value={filter.filter(i => i.checked)}
                                                                        name={key}
                                                                        placeholder={`todas as (${filter.length})`}
                                                                        options={filter}
                                                                        className="basic-multi-select w-full text-sm text-roxo_oficial"
                                                                        classNamePrefix="select"
                                                                        onChange={arr => {
                                                                            handleFilter(arr || [], key);
                                                                            setUsersFilter(arr);
                                                                            refreshOptions(arr);
                                                                        }}
                                                                    />
                                                                )
                                                        )}
                                                    </div>
                                                    <div className="w-full">
                                                        <div className="radio">
                                                            <label>
                                                                <input
                                                                    type="radio"
                                                                    value="option1"
                                                                    checked={selectedList["searchClients"] !== "conexos"}
                                                                    onChange={() => {
                                                                        handleFilter([], "clients");
                                                                        handleFilter("cnpj", "searchClients");
                                                                    }}
                                                                />
                                                                &nbsp;CNPJ
                                                            </label>
                                                        </div>
                                                        <div className="radio">
                                                            <label>
                                                                <input
                                                                    type="radio"
                                                                    value="option2"
                                                                    checked={selectedList["searchClients"] === "conexos"}
                                                                    onChange={() => {
                                                                        handleFilter([], "clients");
                                                                        handleFilter("conexos", "searchClients");
                                                                    }}
                                                                />
                                                                &nbsp;Cód. Conexos
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="w-full">
                                                        <h6 className="mt-4">Duplicar relatório ao usuário</h6>
                                                        <Select
                                                            name="user"
                                                            value={values.user}
                                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                            placeholder=""
                                                            onChange={arr => {
                                                                setFieldValue("user", arr);
                                                                setSelectedUser(arr);
                                                            }}
                                                            options={userOptions}
                                                        />
                                                    </div>
                                                    <div className="w-full">
                                                        <p
                                                            className="mt-8 font-normal underline cursor-pointer text-red text-center"
                                                            onClick={() => {
                                                                setTitleSelection("");
                                                                setSelectedList({});
                                                                setUserSelection({});
                                                                setFilterFields(initialFields);
                                                                resetForm();
                                                            }}>
                                                            Limpar filtros
                                                        </p>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        <div className="w-4/12 ml-3">
                                            <h6 className="mt-4">Processos por etapa</h6>
                                            {filters?.stages?.map((entity, key) => (
                                                <div key={key}>
                                                    <label className="text-C6 font-normal text-default">
                                                        <input
                                                            type="checkbox"
                                                            name={`${"stages"}[]`}
                                                            key={entity.description}
                                                            onChange={() => handleFilter(entity, "stages")}
                                                            checked={entity.checked}
                                                        />
                                                        <span className="check-box-effect"></span>
                                                        {entity.description}
                                                    </label>
                                                </div>
                                            ))}
                                            <h6 className="mt-4">Processos por canal</h6>
                                            {filters?.channels?.map((entity, key) => (
                                                <div key={key}>
                                                    <label className="text-C6 font-normal text-default">
                                                        <input
                                                            type="checkbox"
                                                            name={`${"channels"}[]`}
                                                            key={entity.description}
                                                            onChange={() => handleFilter(entity, "channels")}
                                                            checked={entity.checked}
                                                        />
                                                        <span className="check-box-effect"></span>
                                                        {entity.description}
                                                    </label>
                                                </div>
                                            ))}
                                            <h6 className="mt-4">Processos por modalidade</h6>
                                            {filters?.transportModalities?.map((entity, key) => (
                                                <div key={key}>
                                                    <label className="text-C6 font-normal text-default">
                                                        <input
                                                            type="checkbox"
                                                            name={`${"transportModalities"}[]`}
                                                            key={entity.description}
                                                            onChange={() => handleFilter(entity, "transportModalities")}
                                                            checked={entity.checked}
                                                        />
                                                        <span className="check-box-effect"></span>
                                                        {entity.description}
                                                    </label>
                                                </div>
                                            ))}
                                            <h6 className="mt-4">Tipos de processos</h6>
                                            {filters?.processModalities?.map((entity, key) => (
                                                <div key={key}>
                                                    <label className="text-C6 font-normal text-default">
                                                        <input
                                                            type="checkbox"
                                                            name={`${"processModalities"}[]`}
                                                            key={entity.description}
                                                            onChange={() => handleFilter(entity, "processModalities")}
                                                            checked={entity.checked}
                                                        />
                                                        <span className="check-box-effect"></span>
                                                        {entity.description}
                                                    </label>
                                                </div>
                                            ))}
                                            <h6 className="mt-4">E-mail</h6>
                                            <label className="text-C6 font-normal text-default">
                                                <input
                                                    type="checkbox"
                                                    name="sendEmail"
                                                    id="sendEmail"
                                                    checked={sendEmail}
                                                    onChange={() => setSendEmail(!sendEmail)}
                                                />
                                                <span className="check-box-effect"></span>
                                                Enviar e-mail diário
                                            </label>
                                        </div>
                                        <div className="w-4/12 ml-1">
                                            <h6 className="mt-4">Campos/colunas da tabela</h6>
                                            <DragDropContext
                                                onDragEnd={result => {
                                                    onDragEnd(result);
                                                }}>
                                                <Droppable droppableId="characters">
                                                    {provided => (
                                                        <ul
                                                            className="characters rounded border border-roxo_oficial m-2 p-2"
                                                            {...provided.droppableProps}
                                                            ref={provided.innerRef}>
                                                            {filterFields.map((field, index) => {
                                                                return (
                                                                    <Draggable
                                                                        id={field.id}
                                                                        key={field.id}
                                                                        draggableId={field.id}
                                                                        index={index}>
                                                                        {provided => (
                                                                            <li
                                                                                ref={provided.innerRef}
                                                                                {...provided.draggableProps}>
                                                                                <div
                                                                                    className="p-1 m-1"
                                                                                    Style="background: #F5F5F5;">
                                                                                    <div
                                                                                        className="w-5 m-1 h-5 inline-flex relative"
                                                                                        Style="top: 2px; cursor: grabbing;"
                                                                                        {...provided.dragHandleProps}>
                                                                                        <FaBars />
                                                                                    </div>
                                                                                    <label className="text-C6 font-normal text-default">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            onChange={() => {
                                                                                                var aux = filterFields;
                                                                                                aux[
                                                                                                    index
                                                                                                ].active = !field.active;
                                                                                                aux[index].subFields = aux[
                                                                                                    index
                                                                                                ].subFields.map(subField => {
                                                                                                    subField.active = !subField.active;
                                                                                                    return subField;
                                                                                                });
                                                                                                setFilterFields(aux);
                                                                                                setAction(!action);
                                                                                            }}
                                                                                            checked={field.active}
                                                                                        />
                                                                                        <span className="check-box-effect"></span>
                                                                                        <span>{field.name}</span>
                                                                                    </label>
                                                                                    <div className="inline-flex float-right">
                                                                                        <button
                                                                                            type="button"
                                                                                            className="p-2"
                                                                                            onClick={() => {
                                                                                                var aux = filterFields;
                                                                                                aux[
                                                                                                    index
                                                                                                ].opened = !field.opened;
                                                                                                setFilterFields(aux);
                                                                                                setAction(!action);
                                                                                            }}>
                                                                                            {field.opened ? (
                                                                                                <FaChevronUp />
                                                                                            ) : (
                                                                                                <FaChevronDown />
                                                                                            )}
                                                                                        </button>
                                                                                    </div>
                                                                                </div>

                                                                                <DragDropContext
                                                                                    onDragEnd={result => {
                                                                                        onDragEndChildrens(result, index);
                                                                                    }}>
                                                                                    <Droppable droppableId="childrens">
                                                                                        {provided => (
                                                                                            <ul
                                                                                                className={`childrens ml-6 ${!field.opened && "hidden"
                                                                                                    }`}
                                                                                                {...provided.droppableProps}
                                                                                                ref={provided.innerRef}>
                                                                                                {field.subFields &&
                                                                                                    field.subFields.map(
                                                                                                        (
                                                                                                            field,
                                                                                                            subIndex
                                                                                                        ) => {
                                                                                                            return (
                                                                                                                <Draggable
                                                                                                                    id={
                                                                                                                        field.id
                                                                                                                    }
                                                                                                                    key={
                                                                                                                        field.id
                                                                                                                    }
                                                                                                                    draggableId={
                                                                                                                        field.id
                                                                                                                    }
                                                                                                                    index={
                                                                                                                        subIndex
                                                                                                                    }>
                                                                                                                    {provided => (
                                                                                                                        <li
                                                                                                                            ref={
                                                                                                                                provided.innerRef
                                                                                                                            }
                                                                                                                            {...provided.draggableProps}>
                                                                                                                            <div
                                                                                                                                className="p-1 m-1"
                                                                                                                                Style="background: #F5F5F5;">
                                                                                                                                <div
                                                                                                                                    className="w-5 m-1 h-5 inline-flex relative"
                                                                                                                                    Style="top: 2px; cursor: grabbing;"
                                                                                                                                    {...provided.dragHandleProps}>
                                                                                                                                    <FaBars />
                                                                                                                                </div>
                                                                                                                                <label className="text-C6 font-normal text-default">
                                                                                                                                    <input
                                                                                                                                        type="checkbox"
                                                                                                                                        onChange={() => {
                                                                                                                                            var aux = filterFields;
                                                                                                                                            aux[
                                                                                                                                                index
                                                                                                                                            ].subFields[
                                                                                                                                                subIndex
                                                                                                                                            ].active = !field.active;
                                                                                                                                            setFilterFields(
                                                                                                                                                aux
                                                                                                                                            );
                                                                                                                                            setAction(
                                                                                                                                                !action
                                                                                                                                            );
                                                                                                                                        }}
                                                                                                                                        checked={
                                                                                                                                            field.active
                                                                                                                                        }
                                                                                                                                    />
                                                                                                                                    <span className="check-box-effect"></span>
                                                                                                                                    <span>
                                                                                                                                        {
                                                                                                                                            field.name
                                                                                                                                        }
                                                                                                                                    </span>
                                                                                                                                </label>
                                                                                                                            </div>
                                                                                                                        </li>
                                                                                                                    )}
                                                                                                                </Draggable>
                                                                                                            );
                                                                                                        }
                                                                                                    )}
                                                                                                {provided.placeholder}
                                                                                            </ul>
                                                                                        )}
                                                                                    </Droppable>
                                                                                </DragDropContext>
                                                                            </li>
                                                                        )}
                                                                    </Draggable>
                                                                );
                                                            })}
                                                            {provided.placeholder}
                                                        </ul>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>
                                        </div>
                                    </div>
                                    <button
                                        className="mb-4 bg-roxo_oficial text-white py-2 px-5 rounded-md float-right mt-10 mr-10"
                                        type="button"
                                        onClick={() => {
                                            setTitleSelectionApplied(values.name);
                                            setFilterApplied(selectedList);
                                            setUserSelectionApplied(selectedUser);
                                            refreshApliedFields(filterFields);
                                            setOpenFilterModal(false);
                                            toast.success("Seleção aplicada com sucesso!");
                                        }}>
                                        Somente aplicar
                                    </button>
                                    {filterEditing && (
                                        <button
                                            className="mb-4 bg-roxo_oficial text-white py-2 px-5 rounded-md float-right mt-10 mr-10"
                                            type="button"
                                            disabled={isSubmitting}
                                            onClick={() => {
                                                setSubmitting(true);
                                                setFieldValue("edit", filterEditing);
                                                setIsEdit(true);
                                                setTitleSelectionApplied(values.name);
                                                setTitleSelection(values.name);
                                                setUserSelectionApplied(selectedUser);
                                                setUserSelection(selectedUser);
                                                handleSubmit();
                                            }}>
                                            {isSubmitting ? (
                                                <>
                                                    Salvando <ClipLoader size={10} loading={isSubmitting} />
                                                </>
                                            ) : (
                                                <>Salvar e aplicar alterações</>
                                            )}
                                        </button>
                                    )}
                                    {!filterEditing && (
                                        <button
                                            className="mb-4 bg-roxo_oficial text-white py-2 px-5 rounded-md float-right mt-10 mr-10"
                                            type="submit"
                                            disabled={isSubmitting}>
                                            {isSubmitting ? (
                                                <>
                                                    Salvando <ClipLoader size={10} loading={isSubmitting} />
                                                </>
                                            ) : (
                                                <>Salvar e aplicar</>
                                            )}
                                        </button>
                                    )}
                                </form>
                            )}
                        </Formik>
                    </div>
                ) : (
                    <>
                        <div className="flex justify-between border-b border-C2 pt-2 pb-2">
                            <table className="table-auto w-full">
                                <thead className="border-b border-roxo_oficial">
                                    <th className="w-16 text-left text-roxo_oficial font-normal">Nome</th>
                                    <th className="w-16 text-left text-roxo_oficial font-normal">Alterado em</th>
                                    <th className="w-16 text-left text-roxo_oficial font-normal">Aplicar</th>
                                    <th className="w-16 text-left text-roxo_oficial font-normal">Editar</th>
                                    <th className="w-16 text-left text-roxo_oficial font-normal">Excluir</th>
                                </thead>
                                <tbody>
                                    {selectionList &&
                                        selectionList?.map((data, key) => (
                                            <tr>
                                                <td className="text-left font-normal">{data?.name}</td>
                                                <td className="text-left font-normal">{data?.createdAtFormatted}</td>
                                                <td className="w-16 text-left font-normal">
                                                    <button
                                                        className="p-2 mt-2 rounded-md bg-roxo_oficial_light"
                                                        onClick={() => {
                                                            setTitleSelection(data?.name);
                                                            setTitleSelectionApplied(data?.name);
                                                            setUserSelectionApplied(selectedUser);
                                                            setUserSelection({
                                                                label: data?.user.name,
                                                                value: data?.user.identifier,
                                                            });

                                                            let auxF = JSON.parse(data.filters);

                                                            setFilterFields(auxF.fields);
                                                            refreshApliedFields(auxF.fields);

                                                            delete auxF.fields;

                                                            setSelectedList(auxF);
                                                            setFilterApplied(auxF);

                                                            setFilterEditing(null);
                                                            setModalExport(false);
                                                            setOpenFilterModal(false);

                                                            setOpenTab("Filters Map");
                                                            setAction(!action);
                                                        }}>
                                                        <FiCheck color="#422c76" />
                                                    </button>
                                                </td>
                                                <td className="w-16 text-left font-normal">
                                                    <button
                                                        className="p-2 mt-2 rounded-md bg-roxo_oficial_light"
                                                        onClick={() => {
                                                            setTitleSelection(data?.name);
                                                            setUserSelectionApplied({
                                                                label: data?.user.name,
                                                                value: data?.user.identifier,
                                                            });
                                                            setSelectedUser({
                                                                label: data?.user.name,
                                                                value: data?.user.identifier,
                                                            });
                                                            setUserSelection({
                                                                label: data?.user.name,
                                                                value: data?.user.identifier,
                                                            });

                                                            let auxF = JSON.parse(data.filters);
                                                            setFilterFields(auxF.fields);
                                                            delete auxF.fields;

                                                            setFilterEditing(data.identifier);
                                                            setIsEdit(true);
                                                            setSelectedList(auxF);
                                                            setOpenTab("Filters Map");
                                                            setAction(!action);
                                                            setSendEmail(data.sendEmail);

                                                            setOldUser({
                                                                label: data?.user.name,
                                                                value: data?.user.identifier,
                                                            });
                                                            setOldName(data.name);
                                                        }}>
                                                        <FaPencilAlt />
                                                    </button>
                                                </td>
                                                <td className="w-16 text-left font-normal">
                                                    <button
                                                        className="p-2  mt-2 rounded-md bg-canal_vermelho"
                                                        onClick={() => {
                                                            setFilterEditing(null);

                                                            requests.deleteSelection(data?.identifier).then(() => {
                                                                toast.success("Seleção removida com sucesso!");
                                                                setOldUser(null);
                                                                setUserSelectionApplied(null);
                                                                setUserSelection(null);
                                                                loadSelectionList();
                                                            });
                                                        }}>
                                                        <FaTrashAlt color="#fff" />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="flex">
                            <div className="w-full ml-12">
                                {metaSelectionMap.total_pages > 1 && (
                                    <Paginate
                                        meta={metaSelectionMap}
                                        setMeta={setMetaSelectionMap}
                                        action={actionPagSelectionMap}
                                        setAction={setActionPagSelectionMap}
                                        showDetails={true}
                                    />
                                )}
                            </div>
                            <div className="w-3/12">
                                <button
                                    className="mb-12 py-2 mt-3 bg-roxo_oficial text-white px-5 rounded-md float-right"
                                    type="submit"
                                    onClick={() => {
                                        setSelectedList({});
                                        setFilterEditing(null);
                                        setSelectedUser({
                                            label: window.localStorage.getItem("profile-name"),
                                            value: window.localStorage.getItem("profile-identifier"),
                                        });
                                        setUserSelectionApplied(null);
                                        setUserSelection(null);
                                        setSendEmail(false);
                                        setTitleSelectionApplied("");
                                        setIsEdit(false);
                                        setTitleSelection("");
                                        setOpenTab("Filters Map");
                                    }}>
                                    Nova seleção
                                </button>
                            </div>
                        </div>
                    </>
                )}
            </Modal>

            {/* Modal de exportação */}
            <Modal show={modalExport} borderTitle={false} onClose={actionModalExport} size="" title="">
                <div className="w-full text-center mb-4 mr-4">
                    <h2 className="font-medium text-roxo_oficial">Exportar mapa</h2>
                    <p className="mt-2 font-normal">Deseja realizar o download em qual dos formatos abaixo?</p>
                    <button
                        disabled={isDownloadingCsv}
                        onClick={downloadCsv}
                        className="mt-4 px-3 py-1 font-medium rounded h-10 w-24 bg-roxo_oficial text-white">
                        {isDownloadingCsv ? (
                            <>
                                <ClipLoader color="white" size={20} loading={isDownloadingCsv} /> CSV
                            </>
                        ) : (
                            <>
                                <FiDownload size="1.5rem" className="inline-block" /> CSV
                            </>
                        )}
                    </button>
                    <button
                        disabled={isDownloadingXlsx}
                        onClick={downloadXlsx}
                        className="ml-4 mt-4 px-3 py-1 font-medium rounded h-10 w-24 bg-roxo_oficial text-white">
                        {isDownloadingXlsx ? (
                            <>
                                <ClipLoader color="white" size={20} loading={isDownloadingXlsx} /> XLSX
                            </>
                        ) : (
                            <>
                                <FiDownload size="1.5rem" className="inline-block" /> XLSX
                            </>
                        )}
                    </button>
                </div>
            </Modal>

            {/* Modal de conteúdo longo */}
            <Modal
                show={visibleModalContent}
                borderTitle={false}
                onClose={actionModalContent}
                size="w-3/4"
                title={
                    <div className="flex items-center">
                        <h5 className={`mr-6 text-roxo_oficial font-bold`}>{modalContentTitle}</h5>
                    </div>
                }>
                <div className="text-center mb-4 mr-4">
                    <p className="mt-2 font-normal" dangerouslySetInnerHTML={{ __html: modalContent }} />
                </div>
            </Modal>

            <div className="p-4">
                <Card>
                    <TitlePurple className="text-header uppercase text-roxo_oficial font-medium">
                        MAPA{titleSelectionApplied && ` - ${titleSelectionApplied}`}
                    </TitlePurple>
                    {load ? (
                        <div className="flex flex-col items-center">
                            <ClipLoader size={20} loading={load} />
                        </div>
                    ) : (
                        <Container>
                            <a id="btnLeft" onClick={() => handleScroll("left")}>
                                <CgArrowLeftO size={20} />
                            </a>
                            <a id="btnRight" onClick={() => handleScroll("right")}>
                                <CgArrowRightO size={20} />
                            </a>
                            <div className="border-C2 border-solid pt-2 pb-12 overflow-hidden">
                                <main className="border-t overflow-auto" id="table-responsive">
                                    <div className="flex">
                                        <XpandContainer>
                                            <Table id="table-responsive" className="table-auto w-full text-left mb-6">
                                                <thead className="border-b border-roxo_oficial bg-white">
                                                    <tr Style="height: 65px;">{renderPrimaryTitles()}</tr>
                                                    <tr Style="height: 30px; background: white">{renderSubTitles()}</tr>
                                                </thead>
                                                <tbody>
                                                    {load ? (
                                                        <tr className="">
                                                            <td colspan="20" className="text-center">
                                                                <ClipLoader size={20} loading={load} />
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        <>
                                                            {list.map(map => (
                                                                <tr>{renderContents(map)}</tr>
                                                            ))}
                                                            <tr Style="background: white">
                                                                {meta.total_pages > 1 && (
                                                                    <td colspan="11" className="text-center">
                                                                        <Paginate
                                                                            meta={meta}
                                                                            setMeta={setMeta}
                                                                            action={actionPag}
                                                                            setAction={setActionPag}
                                                                            showDetails={true}
                                                                        />
                                                                    </td>
                                                                )}
                                                            </tr>
                                                        </>
                                                    )}
                                                </tbody>
                                            </Table>
                                            <table></table>
                                        </XpandContainer>
                                    </div>
                                </main>
                            </div>
                        </Container>
                    )}
                </Card>
            </div>
        </Layout>
    );
};

export default ProcessesMapPage;