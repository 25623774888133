import styled from "styled-components";
import { colors } from "../../assets/colors";

const Container = styled.div`
    position: relative;
    a {
        position: fixed;
        bottom: 15%;
        background-color: ${colors.roxo_oficial};
        color: #fff;
        padding: 4px;
        border-radius: 90px;
        cursor: pointer !important;
        transition: background-color 0.4s;
        :hover {
            background-color: ${colors.roxo_2};
        }
    }

    #btnRight {
        position: fixed;
        right: 5px;
    }
    table {
        overflow: auto;
    }
`;
export default Container;
